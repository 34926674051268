import 'eonasdan-bootstrap-datetimepicker'

export const TimePickerRange = selector => {
  TimePicker(`${selector}-start-date`, { format: 'MM/DD/YYYY' })
  TimePicker(`${selector}-end-date`, { format: 'MM/DD/YYYY' })

  $(`${selector}-start-date`).on('dp.change', function(e) {
    $(`${selector}-end-date`)
      .data('DateTimePicker')
      .minDate(e.date)
  })

  $(`${selector}-end-date`).on('dp.change', function(e) {
    $(`${selector}-start-date`)
      .data('DateTimePicker')
      .maxDate(e.date)
  })
}

const TimePicker = (selector, attributes) => {
  const { format, defaultDate } = attributes

  $(selector).datetimepicker({
    format: format,
    defaultDate: defaultDate || new Date()
  })
}

export default TimePicker
