import 'bootstrap-sass/assets/javascripts/bootstrap'
import '../packs-css/audit'
import RemoteListener from '../src/javascript/shared/ujs'
import {TimePickerRange} from 'src/javascript/shared/timePicker'

require('rails-ujs').start()
require('turbolinks').start()

require.context('../images', true);

$(document).on('turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' })

  new TimePickerRange('#status-check')

  new RemoteListener(
    '#subscribe-form, .edit_status_check',
    (response, status) => {
      $('.message').text(response.message)
      $('.email-input').addClass('hidden')
      $('.btn#subscribe').addClass('hidden')

      setTimeout(() => $('.message').text(''), 3000)
    }
  )

  $(document).on('click', '#subscribe-for-updates', function(e) {
    e.preventDefault()
    const $emailInput = $('.email-input')

    if (!$emailInput.is(':visible')) {
      $('.message').text('')
      $('.btn#subscribe').removeClass('hidden')
      $emailInput.removeClass('hidden')
    }
  })

  $(document).on('click', '#export-to-csv', function(e) {
    e.preventDefault()
    const $exportDatePicker = $('.csv-export-date-picker')

    if (!$exportDatePicker.is(':visible')) {
      $('.message').text('')
      $('.btn#export').removeClass('hidden')
      $exportDatePicker.removeClass('hidden')
    }
  })
})